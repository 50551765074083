import React from "react";
import useAuthenticatedQuery from "../hooks/useAuthenticatedQuery";
import { Alert, Button, CircularProgress } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
export default function UnpaidAlerts({ org, orgName }) {
  const { t } = useTranslation("common");
  const { data: paymentCollect, isLoading: paymentCollectLoading } =
    useAuthenticatedQuery<any>(
      `/api/organizations/${org}/hostedPages/collect`,
      {
        retry: false,
        enabled: true,
      }
    );

  return (
    <Alert
      key={org.id}
      severity="warning"
      sx={{ mb: 1, px: 2 }}
      action={
        <Button
          color="inherit"
          size="small"
          href={paymentCollect?.page?.url}
          target="_blank"
          variant="outlined"
          disabled={paymentCollectLoading}
          startIcon={
            paymentCollectLoading ? <CircularProgress size={16} /> : null
          }
          sx={{ mr: 1 }}
        >
          {t("pay-now")}
        </Button>
      }
    >
      {t("unpaid-alert", { orgName })}
    </Alert>
  );
}
